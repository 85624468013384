const InvoiceData = {
    id: "5df3180a09ea1",
    invoice_no: "873512-28",
    fullname: "John Doe",
    email: "john@gmail.com",
    phone: "+91 777-9999",
    address: "lorem ipsum",
    trans_date: "17-08-2022",
    companyID: "10001",
    companyName: "abc company",
    items: [
        {
            desc: "Luxury Ultra thin Wrist Watch",
        },
        {
            desc: "Luxury Ultra thin Wrist Watch",
        },
        {
            desc: "Luxury Ultra thin Wrist Watch",
        },
        {
            desc: "Luxury Ultra thin Wrist Watch",
        },
        {
            desc: "Luxury Ultra thin Wrist Watch",
        },
        {
            desc: "Luxury Ultra thin Wrist Watch",
        },
        {
            desc: "Luxury Ultra thin Wrist Watch",
        },
        {
            desc: "Luxury Ultra thin Wrist Watch",
        },
        {
            desc: "Luxury Ultra thin Wrist Watch",
        }
    ]
};

export default InvoiceData;