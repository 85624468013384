import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
const borderColor = "#00519C";

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderColor: "#3778C2"
    },
    container: {
        flexDirection: "row",
        borderBottomColor: "#00519C",
        backgroundColor: "#ffffff",
        color: "#000000",
        borderBottomWidth: 1,
        alignItems: "center",
        height: 24,
        textAlign: "center",
        fontStyle: "bold",
        fontSize: 12
    },
    textRow: {
        padding: 2,
    },
    jobCard: {
        width: "25%",
        justifyContent: "center",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        height: 24,
        textAlign: "left",
    },
    blank: {
        width: "25%",
        justifyContent: "center",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        height: 24,
        textAlign: "left",

    },
    jobCardDate: {
        width: "25%",
        justifyContent: "center",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        height: 24,
    },
    jobCardNumber: {
        width: "25%",
        justifyContent: "center",
        textAlign: "left",
        paddingLeft: 2
    },
    original: {
        width: "75%",
        justifyContent: "center",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: "left",
        paddingLeft: 2,
        height: 24,

    },
    machineNo: {
        width: "25%",
        justifyContent: "center",
        textAlign: "left",
        paddingLeft: 2,
        height: 24,
    },
    breakDownMould: {
        width: "32%",
        justifyContent: "center",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 2,
        height: 24,
        textAlign: "left",
    },
    breakDownMachine: {
        width: "33%",
        justifyContent: "center",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 2,
        height: 24,
        textAlign: "left",
    },
    mouldNo: {
        width: "25%",
        justifyContent: "center",
        height: 24,
        paddingLeft: 2,
        textAlign: "left",
    },
    checkBoxEnd: {
        justifyContent: "center",
        height: 24,
        width: "5%",
    },
    checkBox: {
        justifyContent: "center",
        height: 24,
        width: "5%",
        borderRightColor: borderColor,
        borderRightWidth: 1
    },
    maintenance: {
        justifyContent: "center",
        height: 24,
        width: "32%",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 2,
        textAlign: "left",
    },
    instruction: {
        justifyContent: "center",
        height: 24,
        width: "33%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1

    },
    repair: {
        justifyContent: "center",
        height: 24,
        width: "20%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 2,
    }

});

const JobCardHeader = ({ invoice }:any) => (
    <View style={styles.tableContainer}>
        {/* Invoice Table Header */}
        <View style={styles.container}>
            <View style={styles.jobCard}>
                <Text style={styles.textRow}>JOB CARD</Text>
            </View>
            <View style={styles.blank}>
                <Text style={styles.textRow}> </Text>
            </View>
            <View style={styles.jobCardDate}>
                <Text style={styles.textRow}>J.C Date: </Text>
            </View>
            <Text style={styles.jobCardNumber}>J.C No:</Text>
        </View>
        <View style={styles.container}>
            <View style={styles.original}>
                <Text style={styles.textRow}>ORIGINAL</Text>
            </View>
            <View style={styles.machineNo}>
                <Text style={styles.textRow}>Machine No:</Text>
            </View>
        </View>
        <View style={styles.container}>
            <View style={styles.breakDownMould}>
                <Text style={styles.textRow}>Breakdown (Mould):</Text>
            </View>
            <View style={styles.checkBox}>
                <Text style={styles.textRow}> </Text>
            </View>
            <View style={styles.breakDownMachine}>
                <Text style={styles.textRow}>Breakdown (Machine):</Text>
            </View>
            <View style={styles.checkBox}>
                <Text style={styles.textRow}> </Text>
            </View>
            <View style={styles.mouldNo}>
                <Text style={styles.textRow}>Mould No:</Text>
            </View>
        </View>
        <View style={styles.container}>
            <Text style={styles.maintenance}>Maintenance:</Text>
            <Text style={styles.checkBox}> </Text>
            <Text style={styles.breakDownMachine}>Instruction:</Text>
            <Text style={styles.checkBox}> </Text>
            <Text style={styles.repair}>Repair:</Text>
            <Text style={styles.checkBoxEnd}> </Text>
        </View>
    </View>
);

export default JobCardHeader;