import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const borderColor = "#00519C";

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderColor: "#3778C2"
    },
    container: {
        flexDirection: "row",
        borderBottomColor: "#00519C",
        backgroundColor: "#fff",
        color: "#000000",
        borderBottomWidth: 1,
        alignItems: "center",
        height: 24,
        textAlign: "center",
        fontStyle: "bold",
        flexGrow: 1,
        fontSize: 12
    },
    jobInstruction: {
        width: "50%",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: "left",
        paddingLeft: 2,
    },
    taskInstruction: {
        width: "15%",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: "left",
        paddingLeft: 2,
        fontSize: 6
    },
    workInstruction: {
        width: "15%",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: "left",
        paddingLeft: 2,
        fontSize: 6
    },
    description: {
        width: "100%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1
    },
    date: {
        width: "20%",
        textAlign: "center",
        paddingLeft: 2,
    },
    checkBox: {
        width: "10%",
        borderRightColor: borderColor,
        borderRightWidth: 1
    },
    descriptionBlank: {
        width: "50%",
        borderRightColor: borderColor,
        borderRightWidth: 1
    },
    timeStart:{
        width: "10%",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: "left",
        paddingLeft: 2,
        fontSize: 8
    },
    timeEnd:{
        width: "10%",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: "left",
        paddingLeft: 2,
        fontSize: 8
    },
    totalTime:{
        width: "10%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 2,
        fontSize: 8
    },
    dateStart:{
        width: "10%",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: "left",
        paddingLeft: 2,
        fontSize: 8
    },
    dateEnd:{
        width: "10%",
        textAlign: "left",
        paddingLeft: 2,
        fontSize: 8
    },
    });

const JobInstruction = ({ invoice }:any) => {
    const rows = invoice.items.map((item: any) => (
        <View style={styles.container}>
            <Text style={styles.descriptionBlank}> </Text>
            <Text style={styles.timeStart}> </Text>
            <Text style={styles.timeEnd}> </Text>
            <Text style={styles.totalTime}> </Text>
            <Text style={styles.dateStart}> </Text>
            <Text style={styles.dateEnd}> </Text>
        </View>
    ));

    return (

        <View style={styles.tableContainer}>
            {/* Invoice Table Header */}
            <View style={styles.container}>
                <Text style={styles.jobInstruction}>Job Instruction</Text>
                <Text style={styles.taskInstruction}>Task Instruction:</Text>
                <Text style={styles.workInstruction}>Work Instruction:</Text>
                <Text style={styles.date}>Date</Text>
            </View>
            <View style={styles.container}>
                <Text style={styles.descriptionBlank}> </Text>
                <Text style={styles.timeStart}>Time Start</Text>
                <Text style={styles.timeEnd}>Time End</Text>
                <Text style={styles.totalTime}>Total</Text>
                <Text style={styles.dateStart}>Start Date</Text>
                <Text style={styles.dateEnd}>End Date</Text>
            </View>
            {rows}
        </View>
    );
}

export default JobInstruction;