import Header from "../../components/Header";
import {
    Alert,
    AlertProps,
    Box,
    Button,
    CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
    Grid,
    Modal,
    TextField,
    Tooltip, Typography,
    useTheme
} from "@mui/material";
import {tokens} from "../../theme";
import {
    DataGrid, GridActionsCellItem, GridCellParams,
    GridColDef,
    GridEventListener,
    GridRowEditStopReasons,
    GridRowId,
    GridRowModes,
    GridRowModesModel,
    GridRowsProp
} from "@mui/x-data-grid";
import Snackbar from '@mui/material/Snackbar';
import React, {useEffect, useState} from "react";

import type {FetchBaseQueryError,} from '@reduxjs/toolkit/query'
import {useGetMachinesByBranchQuery, useGetMachinesQuery} from "../../redux/features/machines";
import EditIcon from '@mui/icons-material/Edit';
import {
    useAddMouldMutation,
    useDeleteMouldMutation,
    useGetMouldsQuery,
    usePutMouldMutation
} from "../../redux/features/moulds";
import {Form, Formik} from "formik";
import Select, {components, ContainerProps} from "react-select";
import * as yup from "yup";
import {useGetBranchesQuery} from "../../redux/features/branches";

interface IRow {
    row: {
        access: string
    }
}

interface IDataRow {
    field: string;
    headerName: string;
    flex?: number;
    cellClassName?: string;
    type?: string;
    headerAlign?: string;
    align?: string;
    renderCell?: ({row: {access}}: IRow) => JSX.Element;
}

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
}

function EditToolbar(props: EditToolbarProps): any {
    const {setRows, setRowModesModel} = props;

    const handleClick = (id: any) => {
        setRows((oldRows) => [...oldRows, {id, name: '', age: '', isNew: true}]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: {mode: GridRowModes.Edit, fieldToFocus: 'name'},
        }));
    };
}

function getRowId(row: any) {
    return row.id;
}

function isFetchBaseQueryError(
    error: unknown
): error is FetchBaseQueryError {
    return typeof error === 'object' && error != null && 'status' in error
}

const Moulds = () => {
    const theme = useTheme();
    const colours = tokens(theme.palette.mode);
    const {data: moulds,} = useGetMouldsQuery({});
    const {data: branches,} = useGetBranchesQuery({});

    const [snackbar, setSnackbar] = React.useState<Pick<
        AlertProps,
        'children' | 'severity'
    > | null>(null);
    const machineStatus = ['active', 'offline', 'maintenance'];
    const [open, setOpen] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [editRow, setEditRow] = useState<{}>({});
    const [deleteId, setDeleteId] = useState<number>(0);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [addIsSuccess, setAddIsSuccess] = useState(false);
    const [updateIsSuccess, setUpdateIsSuccess] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteIsSuccess, setDeleteIsSuccess] = useState(false);
    const [branchId, setBranchId] = useState(0)
    const {data: machines,} = useGetMachinesByBranchQuery(branchId);
    const [addMould, {data: addData, error: addError, isSuccess, isLoading}] = useAddMouldMutation();
    const [putMould, {
        data: updateData,
        error: updateError,
        isSuccess: updateSuccess,
        isLoading: updateLoading
    }] = usePutMouldMutation();
    const [deleteMould, {
        data: deleteData,
        error: deleteError,
        isSuccess: deleteSuccess,
        isLoading: deleteLoading
    }] = useDeleteMouldMutation();
    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleCloseSnackbar = () => setSnackbar(null);

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        setSnackbar({children: error.toString(), severity: 'error'});
    }, []);

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
        // const editedRow = moulds.find((row: any) => row.ID === id);
        // if (editedRow!.isNew) {
        //     setRows(rows.filter((row: any) => row.ID !== id));
        // }
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columns: GridColDef[] = [
        {field: "id", headerName: "ID"},
        {field: "serial", headerName: "Serial", flex: 0.1},
        {field: "product", headerName: "Product", flex: 0.1},
        {field: "manufactureCycleTime", headerName: "OE M/C Cycle Seconds", flex: 0.1},
        {field: "actualCycleTime", headerName: "Production M/C Cycle Seconds", flex: 0.1},
        {field: "unitsPerCycle", headerName: "Units Per M/C Cycle"},
        {field: "weight", headerName: "Weight"},
        {field: "specification", headerName: "Specification"},
        {field: "owner", headerName: "Owner"},
        {field: "mouldType", headerName: "Mould TYpe"},
        {field: "cavities", headerName: "Cavities"},
        {field: "status", headerName: "Status"},
        {field: "branchLocation", headerName: "Branch Location"},
        {field: "branchId", headerName: "Branch ID"},
        {
            field: "createdAt",
            headerName: "Created At Date",
            flex: 0.2,
        },
        {
            field: "updatedAt",
            headerName: "Updated At",
            flex: 0.2,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            renderCell: (params: GridCellParams) => {
                const onClick = () => {
                    setEditRow(params.row);
                    setIsModalOpen(true);
                };

                return <GridActionsCellItem
                    icon={<EditIcon/>}
                    label="Edit"
                    className="textPrimary"
                    onClick={onClick}
                    color="inherit"
                />;
            },
        },
    ];

    const initialValues = Object.keys(editRow).length > 0 ? {
        ...editRow,
    } : {
        serial: '',
        product: '',
        manufactureCycleTime: '',
        unitsPerCycle: '',
        status: '',
        weight: '',
        specification: '',
        owner: '',
        mouldType: '',
        cavities: '',
        machineIds: [],
        branchId: '',
        id: 0
    };

    const handleSubmit = (values: any, actions: { setSubmitting: (arg0: boolean) => void; }) => {
        Object.keys(editRow).length > 0 ? putMould(values) : addMould(values)
        actions.setSubmitting(false);
        setSubmitted(true)
        setEditRow([])
        setIsModalOpen(false);
    };

    const handleDeleteSubmit = () => {
        setSubmitted(true)
        setEditRow([])
        setIsModalOpen(false);
        deleteMould(deleteId)
    }

    const onMachineChange = (machinesSelected: any) => {
        return machinesSelected.map((machine: any) => machine.value);
    }

    useEffect(() => {
        if (submitted && (isSuccess)) {
            setAddIsSuccess(true)
        }
    }, [isSuccess]);

    useEffect(() => {
        if (submitted && (updateSuccess)) {
            setUpdateIsSuccess(true)
        }
    }, [updateSuccess]);

    useEffect(() => {
        if (submitted && (deleteSuccess)) {
            setDeleteIsSuccess(true);
        }
    }, [deleteSuccess]);
    useEffect(() => {
        if (submitted && (addError || updateError || deleteError)) {
            setOpen(true);
        }
    }, [addError, updateError, deleteError]);

    const SelectContainer = ({
                                 children,
                                 ...props
                             }: ContainerProps) => {
        return (
            <Tooltip title={""} content={'customise your select container'}>
                <components.SelectContainer {...props}>
                    {children}
                </components.SelectContainer>
            </Tooltip>
        );
    };

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            backgroundColor: 'lightgray',
            padding: '10px 10px 10px 10px',
            border: '1px solid black',
            boxShadow: '0 2px 4px rgba(0,0,0,.2)',
            spacing: 10,
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'white' : 'black',
            backgroundColor: state.isSelected ? 'hotpink' : 'white',
        }),
    };

    const checkoutSchema = yup.object().shape({
        serial: yup.string().required(),
        product: yup.string().required(),
        manufactureCycleTime: yup.string().required(),
        unitsPerCycle: yup.string().required(),
        status: yup.string().required().matches(/active|offline|maintenance/, 'Invalid status'),
        weight: yup.string().required(),
        specification: yup.string().required(),
        owner: yup.string().required(),
        mouldType: yup.string().required(),
        cavities: yup.number().required().integer().positive(),
        machineIds: yup.array().required().min(1, 'Please select at least one machine'),
        id: yup.number().notRequired(),
        branchId: yup.number().required()
    });

    const handleClose = () => {
        setOpen(false);
        setConfirmDelete(false)
    };
    return (
        <Box m="20px">
            <Header
                title="Moulds"
                subtitle="List of Moulds"
            />
            <Box display="flex" justifyContent="flex-start">
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsModalOpen(true)}
                    href={''}>
                    Add Mould
                </Button>
            </Box>
            <Dialog open={confirmDelete} onClose={handleClose}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>Are you sure you want to delete this item?</DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={() => {
                        handleDeleteSubmit();
                        handleClose()
                    }} color="secondary">Yes</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deleteIsSuccess} onClose={() => {
                setDeleteIsSuccess(false);
                setSubmitted(false)
            }}>
                <DialogTitle>Success</DialogTitle>
                <DialogContent>The item has been successfully deleted.</DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setDeleteIsSuccess(false);
                        setSubmitted(false)
                    }}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={addIsSuccess} onClose={() => {
                setAddIsSuccess(false);
                setSubmitted(false)
            }}>
                <DialogTitle>Success</DialogTitle>
                <DialogContent>Your mould has been added successfully.</DialogContent>
                <DialogContent>
                    <p id="modal-modal-description">ID: {addData ? addData.id : null}</p>
                    <p id="modal-modal-description">Created At: {addData ? addData.createdAt : null}</p>
                    <p id="modal-modal-description">Message: {addData?.message}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setAddIsSuccess(false);
                        setSubmitted(false)
                    }}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={updateIsSuccess} onClose={() => {
                setUpdateIsSuccess(false);
                setSubmitted(false)
            }}>
                <DialogTitle>Success</DialogTitle>
                <DialogContent>Your mould has been updated successfully.</DialogContent>
                <DialogContent>
                    <p id="modal-modal-description">ID: {updateData ? updateData.id : null}</p>
                    <p id="modal-modal-description">Updated At: {updateData ? updateData.updatedAt : null}</p>
                    <p id="modal-modal-description">Message: {updateData?.message}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setUpdateIsSuccess(false);
                        setSubmitted(false)
                    }}>Close</Button>
                </DialogActions>
            </Dialog>
            <Modal
                open={open}
                onClose={() => {
                    setOpen(false);
                    setSubmitted(false)
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4
                }}>
                    <h2 id="modal-modal-title">Error</h2>
                    <p id="modal-modal-description">There was an error in the action.</p>
                    <p id="modal-modal-description">ERROR: {addData?.message}</p>
                    <p id="modal-modal-description">ERROR: {updateData?.message}</p>
                    <p id="modal-modal-description">ERROR: {deleteData?.message}</p>
                </Box>
            </Modal>
            <Modal
                open={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false)
                    setEditRow([])
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '40%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 800,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4
                }}>

                    <h2 id="modal-modal-title">{Object.keys(editRow).length > 0 ? "Update Mould" : "Add new Mould"}</h2>
                    <Formik initialValues={initialValues}
                            onSubmit={(values, formikHelpers) => handleSubmit(values, formikHelpers)}
                            validationSchema={checkoutSchema}
                    >
                        {({values, errors, touched, handleChange, handleBlur}) => (
                            <Form>
                                <Grid container flex={'auto'} spacing={2}>
                                    <Grid style={{display: 'flex', flexDirection: 'column'}}>
                                        <Grid style={{display: 'flex', flexDirection: 'row'}}>
                                            <Grid item xs={3}>
                                                <Box
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    style={{}}
                                                >Serial:</Box>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Serial"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.serial}
                                                    name="serial"
                                                    error={!!touched.serial && !!errors.serial}
                                                    helperText={touched.serial && errors.serial}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid style={{display: 'flex', flexDirection: 'row'}}>
                                            <Grid item xs={3}>
                                                <Box
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >Product:</Box>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Product"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.product}
                                                    name="product"
                                                    error={!!touched.product && !!errors.product}
                                                    helperText={touched.product && errors.product}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid style={{display: 'flex', flexDirection: 'row'}}>
                                            <Grid item xs={3}>
                                                <Box
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >Manufacture Cycle Time:</Box>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="ManufactureCycleTime"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.manufactureCycleTime}
                                                    name="manufactureCycleTime"
                                                    error={!!touched.manufactureCycleTime && !!errors.manufactureCycleTime}
                                                    helperText={touched.manufactureCycleTime && errors.manufactureCycleTime}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid style={{display: 'flex', flexDirection: 'row'}}>
                                            <Grid item xs={3}>
                                                <Box
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >Units Per Cycle:</Box>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="UnitsPerCycle"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.unitsPerCycle}
                                                    name="unitsPerCycle"
                                                    error={!!touched.unitsPerCycle && !!errors.unitsPerCycle}
                                                    helperText={touched.unitsPerCycle && errors.unitsPerCycle}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid style={{display: 'flex', flexDirection: 'row'}}>
                                            <Grid item xs={3}>
                                                <Box
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >Status:</Box>
                                            </Grid>
                                            <Grid item xs={9}>
                                                {Object.keys(editRow).length > 0 ?
                                                    <Select
                                                        styles={customStyles}
                                                        closeMenuOnSelect={true}
                                                        options={machineStatus && machineStatus.map((status: any) => ({
                                                            value: status,
                                                            label: status
                                                        }))}
                                                        onChange={(status: any) => {
                                                            const event = {
                                                                target: {
                                                                    name: 'status',
                                                                    value: status.value
                                                                }
                                                            };
                                                            handleChange(event);
                                                        }}
                                                        defaultValue={{value: values.status, label: values.status}}
                                                        onBlur={handleBlur}
                                                        placeholder={"Select Status"}
                                                    />
                                                    :
                                                    <Select
                                                        styles={customStyles}
                                                        closeMenuOnSelect={true}
                                                        components={{SelectContainer}}
                                                        options={machineStatus && machineStatus.map((status: any) => ({
                                                            value: status,
                                                            label: status
                                                        }))}
                                                        onChange={(status: any) => {
                                                            const event = {
                                                                target: {
                                                                    name: 'status',
                                                                    value: status.value
                                                                }
                                                            };
                                                            handleChange(event);
                                                        }}
                                                        onBlur={handleBlur}
                                                        placeholder={"Select Status"}
                                                    />}
                                                {errors.status &&
                                                    <span className="error">{errors.status}</span>
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid style={{display: 'flex', flexDirection: 'row'}}>
                                            <Grid item xs={3}>
                                                <Box
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >Weight:</Box>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Weight"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.weight}
                                                    name="weight"
                                                    error={!!touched.weight && !!errors.weight}
                                                    helperText={touched.weight && errors.weight}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid style={{display: 'flex', flexDirection: 'row'}}>
                                            <Grid item xs={3}>
                                                <Box
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >Specification:</Box>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Specification"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.specification}
                                                    name="specification"
                                                    error={!!touched.specification && !!errors.specification}
                                                    helperText={touched.specification && errors.specification}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid style={{display: 'flex', flexDirection: 'row'}}>
                                            <Grid item xs={3}>
                                                <Box
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >Owner:</Box>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Owner"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.owner}
                                                    name="owner"
                                                    error={!!touched.owner && !!errors.owner}
                                                    helperText={touched.owner && errors.owner}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid style={{display: 'flex', flexDirection: 'row'}}>
                                            <Grid item xs={3}>
                                                <Box
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >Mould Type:</Box>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Mould Type"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.mouldType}
                                                    name="mouldType"
                                                    error={!!touched.mouldType && !!errors.mouldType}
                                                    helperText={touched.mouldType && errors.mouldType}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid style={{display: 'flex', flexDirection: 'row'}}>
                                            <Grid item xs={3}>
                                                <Box
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >Cavities:</Box>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Cavities"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.cavities}
                                                    name="cavities"
                                                    error={!!touched.cavities && !!errors.cavities}
                                                    helperText={touched.cavities && errors.cavities}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid style={{display: 'flex', flexDirection: 'row'}}>

                                            <Grid item xs={3}>
                                                <Box
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >Branches:</Box>
                                            </Grid>
                                            <Grid item xs={9}>
                                                {Object.keys(editRow).length > 0 ?

                                                    <Select
                                                        styles={customStyles}
                                                        closeMenuOnSelect={true}
                                                        options={branches && branches.map((branch: any) => ({
                                                            value: branch.id,
                                                            label: branch.branchLocation
                                                        }))}
                                                        onChange={(branch: any) => {
                                                            setBranchId(branch.value)
                                                            const event = {
                                                                target: {
                                                                    name: 'branchId',
                                                                    value: branch.value
                                                                }
                                                            };
                                                            handleChange(event);
                                                        }}
                                                        defaultValue={() => {
                                                            const temp = branches.find((b: any) => b.id === values.branchId);
                                                            return {
                                                                value: temp ? temp.id : values.branchId,
                                                                label: temp ? temp.branchLocation : values.branchId
                                                            }
                                                        }}
                                                        onBlur={handleBlur}
                                                        placeholder={"Select Branch"}
                                                    />
                                                    :
                                                    <Select
                                                        styles={customStyles}
                                                        closeMenuOnSelect={true}
                                                        components={{SelectContainer}}
                                                        options={branches && branches.map((branch: any) => ({
                                                            value: branch.id,
                                                            label: branch.branchLocation
                                                        }))}
                                                        onChange={(branch: any) => {
                                                            setBranchId(branch.value)
                                                            const event = {
                                                                target: {
                                                                    name: 'branchId',
                                                                    value: branch.value
                                                                }
                                                            };
                                                            handleChange(event);
                                                        }}
                                                        onBlur={handleBlur}
                                                        placeholder={"Select Branch"}
                                                    />
                                                }
                                                {errors.branchId &&
                                                    <span className="error">{errors.branchId}</span>
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid style={{display: 'flex', flexDirection: 'row'}}>
                                            <Grid item xs={3}>
                                                <Box
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >Machines:</Box>
                                            </Grid>
                                            <Grid item xs={9}>
                                                {Object.keys(editRow).length > 0 ?
                                                    <Select
                                                        styles={customStyles}
                                                        isMulti
                                                        closeMenuOnSelect={true}
                                                        options={machines && machines.map((machine: any) => ({
                                                            value: machine.id,
                                                            label: machine.machineName
                                                        }))}
                                                        onChange={selectedOptions => {
                                                            const event = {
                                                                target: {
                                                                    name: 'machineIds',
                                                                    value: onMachineChange(selectedOptions)
                                                                }
                                                            };
                                                            handleChange(event);
                                                        }}
                                                        defaultValue={values.machineIds?.map((machine: any) => {
                                                            const temp = machines.find((m: any) => m.id === machine);
                                                            return {
                                                                value: temp ? temp.id : machine,
                                                                label: temp ? temp.machineName : machine
                                                            }
                                                        })}
                                                        onBlur={handleBlur}
                                                        placeholder={"Select Machines"}
                                                    />
                                                    :
                                                    <Select
                                                        styles={customStyles}
                                                        isMulti
                                                        closeMenuOnSelect={true}
                                                        components={{SelectContainer}}
                                                        options={machines && machines.map((machine: any) => ({
                                                            value: machine.id,
                                                            label: machine.machineName
                                                        }))}
                                                        onChange={selectedOptions => {
                                                            const event = {
                                                                target: {
                                                                    name: 'machineIds',
                                                                    value: onMachineChange(selectedOptions)
                                                                }
                                                            };
                                                            handleChange(event);
                                                        }}
                                                        onBlur={handleBlur}
                                                        placeholder={"Select Machines"}
                                                    />
                                                }
                                                {errors.machineIds &&
                                                    <span className="error">{errors.machineIds}</span>
                                                }
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Box display="flex" justifyContent="end" mt="20px">
                                    {Object.keys(editRow).length > 0 ?
                                        <Button type="submit" color="secondary" variant="contained" onClick={() => {
                                            setConfirmDelete(true);
                                            setDeleteId(values.id ? values.id : 0)
                                        }}>
                                            Delete
                                        </Button> : null
                                    }
                                    <Button type="submit" color="secondary" variant="contained" disabled={isLoading}>
                                        {isLoading || updateLoading ?
                                            <CircularProgress/> : Object.keys(editRow).length > 0 ? "Update Mould" : "Add new Mould"}
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Modal>
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar}/>
                </Snackbar>
            )}
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colours.palette.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colours.palette.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colours.palette.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colours.palette.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colours.palette.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colours.palette.grey[100]} !important`,
                    },
                }}
            >
                {moulds &&
                    <DataGrid
                        getRowId={getRowId}
                        rows={moulds}
                        columns={columns}
                        editMode="row"
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        slots={{
                            toolbar: EditToolbar,
                        }}
                        slotProps={{
                            toolbar: {moulds, setRowModesModel},
                        }}
                    />}
            </Box>
        </Box>
    );
};


export default Moulds;