import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const borderColor = "#00519C";

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        borderTopWidth: 1,
        borderRightWidth: 1,
        borderLeftWidth: 1,
        borderColor: "#3778C2"
    },
    container: {
        flexDirection: "row",
        borderBottomColor: "#00519C",
        backgroundColor: "#fff",
        color: "#000000",
        borderBottomWidth: 1,
        alignItems: "center",
        height: 24,
        textAlign: "center",
        fontStyle: "bold",
        flexGrow: 1,
        fontSize: 12
    },
    descriptionOfRequest: {
        width: "25%",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: "left",
        paddingLeft: 2,
    },
    requestedBy: {
        width: "25%",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: "left",
        paddingLeft: 2,
    },
    department: {
        width: "25%",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: "left",
        paddingLeft: 2,
    },
    description: {
        width: "100%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1
    }

});

const DescriptionOfRequirement = ({ invoice }:any) => {
    const rows = invoice.items.map((item: any) => (
        <View style={styles.container}>
            <Text style={styles.description}></Text>
        </View>
    ));

    return (

        <View style={styles.tableContainer}>
            {/* Invoice Table Header */}
            <View style={styles.container}>
                <Text style={styles.descriptionOfRequest}>Description Of Request</Text>
                <Text style={styles.requestedBy}>Requested By:</Text>
                <Text style={styles.department}>Department:</Text>
            </View>
            <View style={styles.container}>
                <Text style={styles.description}></Text>
            </View>
            {rows}
        </View>
    );
}

export default DescriptionOfRequirement;