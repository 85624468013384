import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import invoiceData from "./InvoiceData";
import JobCardHeader from "./JobCardHeader";
import DescriptionOfRequirement from "./DescriptionOfRequirement";
import JobInstruction from "./JobInstruction";
import SparesUsed from "./SparesUsed";
import Remarks from "./Remarks";

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        display: "flex"
    },
});

const JobCard = () => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <JobCardHeader invoice={invoiceData}/>
                    <DescriptionOfRequirement invoice={invoiceData}/>
                    <JobInstruction invoice={invoiceData}/>
                    <SparesUsed invoice={invoiceData}/>
                    <SparesUsed invoice={invoiceData}/>
                    <Remarks invoice={invoiceData}/>
                </View>
            </Page>
        </Document>
    );
};

export default JobCard;