import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {store} from "../store";
import {baseQueryWithReauth, productBaseQuery} from "./apiSlice";
import {BaseQueryArg} from "@reduxjs/toolkit/dist/query/baseQueryTypes";

export const machinesApi = createApi({
    reducerPath: 'machinesApi',
    baseQuery: baseQueryWithReauth(productBaseQuery),

    tagTypes: ['machines', 'branchMachines'],
    endpoints: build => ({
        GetMachines: build.query({
            query:() => ({
                url: `/machines/`,
                method: 'GET',
            }),
            transformResponse: (response: any) =>  {
                return response.machines},
            providesTags: ['machines']
        }),
        GetMachinesByBranch: build.query({
            query:(branchId: number) => ({
                url: `/machines/branch/${branchId}`,
                method: 'GET',
            }),
            transformResponse: (response: any) =>  {
                return response.machines},
            providesTags: ['branchMachines']
        }),
        AddMachine: build.mutation({
            query:(machine: any) => ({
                url: `/machines/`,
                method: 'POST',
                body: machine
            }),
            transformResponse: (response: any) =>  {
                response.machine.message = response.message || ""
                return response.machine},
            invalidatesTags: ['machines']
        }),
        UpdateMachine: build.mutation({
            query:(machine: any) => ({
                url: `/machines/`,
                method: 'PUT',
                body: machine
            }),
            transformResponse: (response: any) =>  {
                response.machine.message = response.message || ""
                return response.machine},
            invalidatesTags: ['machines']
        }),
        DeleteMachine: build.mutation({
            query:(id: number) => ({
                url: `/machines/${id}`,
                method: 'DELETE',
            }),
            transformResponse: (response: any) =>  {
                response.machine.message = response.message || ""
                return response.machine
            },
            invalidatesTags: ['machines']
        })
    })
})

export const  {
    useGetMachinesQuery,
    useGetMachinesByBranchQuery,
    useAddMachineMutation,
    useUpdateMachineMutation,
    useDeleteMachineMutation
} = machinesApi

