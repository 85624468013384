import { createApi } from '@reduxjs/toolkit/query/react'
import {baseQueryWithReauth, productBaseQuery} from "./apiSlice";

export const mouldsApi = createApi({
    reducerPath: 'mouldsApi',
    baseQuery: baseQueryWithReauth(productBaseQuery),

    tagTypes: ['moulds'],
    endpoints: build => ({
        GetMoulds: build.query({
            providesTags: ['moulds'],
            query:() => ({
                url: `/moulds/`,
                method: 'GET',
            }),
            transformResponse: (response: any) =>  {
                return response.moulds},
        }),
        AddMould: build.mutation({
            query:(mould: any) => ({
                url: `/moulds/`,
                method: 'POST',
                body: mould
            }),
            transformResponse: (response: any) =>  {
                response.mould.message = response.message || ""
                return response.mould},
            invalidatesTags: ['moulds']
        }),
        PutMould: build.mutation({
            query:(mould: any) => ({
                url: `/moulds/`,
                method: 'PUT',
                body: mould
            }),
            transformResponse: (response: any) =>  {
                response.mould.message = response.message || ""
                return response.mould},
            invalidatesTags: ['moulds']
        }),
        DeleteMould: build.mutation({
            query:(id: number) => ({
                url: `/moulds/${id}`,
                method: 'DELETE',
            }),
            transformResponse: (response: any) =>  {
                response.mould.message = response.message || ""
                return response.mould},
            invalidatesTags: ['moulds']
        }),
        GetMouldsByMachineId: build.query({
            query:(machineId: number) => ({
                url: `/moulds/machineId/${machineId}`,
                method: 'GET',
            }),
            transformResponse: (response: any) =>  {
                return response.moulds}
        }),
    })
})

export const  {
    useGetMouldsQuery,
    useGetMouldsByMachineIdQuery,
    useAddMouldMutation,
    usePutMouldMutation,
    useDeleteMouldMutation
} = mouldsApi

