import {applyMiddleware, configureStore} from "@reduxjs/toolkit";
import logger from "./middleware/logger";
import {usersApi} from "./features/uses";
import {machinesApi} from "./features/machines";
import {mouldsApi} from "./features/moulds";
import user from "./user";
import {machinePerformanceApi} from "./features/machinePerformance";
import {customrsApi} from "./features/customers";
import authSlice from "./features/authSlice";
import {api} from "./features/apiSlice";
import {branchesApi} from "./features/branches";

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        auth: authSlice,
        [usersApi.reducerPath]: usersApi.reducer,
        [machinesApi.reducerPath]: machinesApi.reducer,
        [mouldsApi.reducerPath]: mouldsApi.reducer,
        [machinePerformanceApi.reducerPath]: machinePerformanceApi.reducer,
        [customrsApi.reducerPath]: customrsApi.reducer,
        [branchesApi.reducerPath]: branchesApi.reducer,
        user: user
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat([
            api.middleware,usersApi.middleware,
            machinesApi.middleware, mouldsApi.middleware,
            machinePerformanceApi.middleware,
            customrsApi.middleware,
            branchesApi.middleware])
        .concat(logger)
})
