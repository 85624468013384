import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {store} from "../store";
import {baseQueryWithReauth, productBaseQuery} from "./apiSlice";
import {BaseQueryArg} from "@reduxjs/toolkit/dist/query/baseQueryTypes";

export const branchesApi = createApi({
    reducerPath: 'branchesApi',
    baseQuery: baseQueryWithReauth(productBaseQuery),

    tagTypes: ['branches'],
    endpoints: build => ({
        GetBranches: build.query({
            query:() => ({
                url: `/branches/`,
                method: 'GET',
            }),
            transformResponse: (response: any) =>  {
                return response.branches},
            providesTags: ['branches']
        }),
        AddBranch: build.mutation({
            query:(branch: any) => ({
                url: `/branches/`,
                method: 'POST',
                body: branch
            }),
            transformResponse: (response: any) =>  {
                response.branch.message = response.message || ""
                return response.branch},
            invalidatesTags: ['branches']
        }),
        UpdateBranch: build.mutation({
            query:(branch: any) => ({
                url: `/branches/`,
                method: 'PUT',
                body: branch
            }),
            transformResponse: (response: any) =>  {
                response.branch.message = response.message || ""
                return response.branch},
            invalidatesTags: ['branches']
        }),
        DeleteBranch: build.mutation({
            query:(id: number) => ({
                url: `/branches/${id}`,
                method: 'DELETE',
            }),
            transformResponse: (response: any) =>  {
                response.branch.message = response.message || ""
                return response.branch
            },
            invalidatesTags: ['branches']
        })
    })
})

export const  {
    useGetBranchesQuery,
    useAddBranchMutation,
    useUpdateBranchMutation,
    useDeleteBranchMutation
} = branchesApi

