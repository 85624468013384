import {Box} from "@mui/material";
import Header from "../../components/Header";
import React from "react";
import JobCard from "../../PDF/JobCard";
import { PDFViewer } from '@react-pdf/renderer';

const Dashboard = () => {
    return (
        <Box m="20px">
            <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                <Header title={"DASHBOARD"} subtitle={"Welcome to your dashboard"}></Header>
            </Box>
            <Box sx={{height: 100}}>
                <PDFViewer style={{ width: '100%', height: '500px' }}>
                    <JobCard />
                </PDFViewer>
            </Box>
            <Box height="75vh">
            </Box>
        </Box>

    );
}

export default Dashboard;