import Header from "../../components/Header";
import {
    Alert,
    AlertProps,
    Box,
    Button,
    CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
    Grid,
    Modal,
    TextField,
    Tooltip,
    useTheme
} from "@mui/material";
import {tokens} from "../../theme";
import {
    DataGrid, GridActionsCellItem, GridCellParams,
    GridColDef,
    GridEventListener,
    GridRowEditStopReasons,
    GridRowId,
    GridRowModes,
    GridRowModesModel,
    GridRowsProp
} from "@mui/x-data-grid";
import Snackbar from '@mui/material/Snackbar';
import React, {useEffect, useState} from "react";
import {useGetUsersQuery} from '../../redux/features/uses'

import type {FetchBaseQueryError,} from '@reduxjs/toolkit/query'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {Form, Formik} from "formik";
import Select, {components, ContainerProps} from "react-select";
import * as yup from "yup";
import {
    useAddCustomerMutation,
    useDeleteCustomerMutation,
    useGetCustomersQuery,
    useUpdateCustomerMutation
} from "../../redux/features/customers";

interface IRow {
    row: {
        access: string
    }
}

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
}

function EditToolbar(props: EditToolbarProps): any {
    const {setRows, setRowModesModel} = props;

    const handleClick = (id: any) => {
        setRows((oldRows) => [...oldRows, {id, name: '', age: '', isNew: true}]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: {mode: GridRowModes.Edit, fieldToFocus: 'name'},
        }));
    };
}

function getRowId(row: any) {
    return row.id;
}

function isFetchBaseQueryError(
    error: unknown
): error is FetchBaseQueryError {
    return typeof error === 'object' && error != null && 'status' in error
}

const Customers = () => {
    const theme = useTheme();
    const colours = tokens(theme.palette.mode);
    const {data: customers,} = useGetCustomersQuery({});
    const [snackbar, setSnackbar] = React.useState<Pick<
        AlertProps,
        'children' | 'severity'
    > | null>(null);
    const machineStatus = ['active', 'offline', 'maintenance'];

    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [editRow, setEditRow] = useState<{}>({});

    const [open, setOpen] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);

    const [updateOrAddModalOpen, setUpdateOrAddModalOpen] = React.useState(false);
    const [addIsSuccess, setAddIsSuccess] = React.useState(false);
    const [updateIsSuccess, setUpdateIsSuccess] = React.useState(false);
    const [deleteIsSuccess, setDeleteIsSuccess] = React.useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteId, setDeleteId] = useState<number>(0);

    const [addCustomer, {data: addData, error: addError, isSuccess: addSuccess, isLoading: addLoading}] = useAddCustomerMutation();
    const [updateCustomer, {data: updateData, error: updateError, isSuccess: updateSuccess, isLoading: updateLoading}] = useUpdateCustomerMutation();
    const [deleteCustomer, {data: deleteData, error: deleteError, isSuccess: deleteSuccess, isLoading: deleteLoading}] = useDeleteCustomerMutation();


    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleSubmit = (values: any, actions: { setSubmitting: (arg0: boolean) => void; }) => {
        Object.keys(editRow).length > 0 ? updateCustomer(values) : addCustomer(values)
        actions.setSubmitting(false);
        setSubmitted(true)
        setEditRow([])
        setUpdateOrAddModalOpen(false);
    };

    const handleCloseSnackbar = () => setSnackbar(null);

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        setSnackbar({children: error.toString(), severity: 'error'});
    }, []);

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
    };

    useEffect(() => {
        if (submitted && (addSuccess)) {
            setAddIsSuccess(true)
        }
    }, [addSuccess]);

    useEffect(() => {
        if (submitted && (updateSuccess)) {
            setUpdateIsSuccess(true)
        }
    }, [updateSuccess]);

    useEffect(() => {
        if (submitted && (deleteSuccess)) {
            setDeleteIsSuccess(true);
        }
    }, [deleteSuccess]);
    useEffect(() => {
        if (submitted && (addError || updateError || deleteError)) {
            setOpen(true);
        }
    }, [addError, updateError, deleteError]);

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
        const editedRow = customers.find((row: any) => row.ID === id);
        // if (editedRow!.isNew) {
        //     setRows(rows.filter((row: any) => row.ID !== id));
        // }
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const initialValues = Object.keys(editRow).length > 0 ? {
        ...editRow,
    } : {
        companyName: '',
        address: '',
        postalAddress: '',
        vatNo: '',
        status: '',
        id: 0
    };

    const checkoutSchema = yup.object().shape({
        companyName: yup.string().required(),
        address: yup.string().required(),
        postalAddress: yup.string().required(),
        vatNo: yup.string().required(),
        status: yup.string().required()
    });

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            backgroundColor: 'lightgray',
            padding: '10px 10px 10px 10px',
            border: '1px solid black',
            boxShadow: '0 2px 4px rgba(0,0,0,.2)',
            spacing: 10,
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'white' : 'black',
            backgroundColor: state.isSelected ? 'hotpink' : 'white',
        }),
    };

    const SelectContainer = ({
                                 children,
                                 ...props
                             }: ContainerProps) => {
        return (
            <Tooltip title={""} content={'customise your select container'}>
                <components.SelectContainer {...props}>
                    {children}
                </components.SelectContainer>
            </Tooltip>
        );
    };

    const handleConfirmClose = () => {
        setUpdateOrAddModalOpen(false);
        setConfirmDelete(false)
    };

    const handleDeleteSubmit = () => {
        setSubmitted(true)
        setEditRow([])
        setUpdateOrAddModalOpen(false);
        deleteCustomer(deleteId)
    }

    const columns: GridColDef[] = [
        {field: "id", headerName: "ID", flex: 0.1},
        {field: "companyName", headerName: "Company Name", flex: 0.2},
        {field: "address", headerName: "Address", flex: 0.2},
        {field: "postalAddress", headerName: "PostalAddress", flex: 0.2},
        {field: "vatNo", headerName: "Vat No", flex: 0.2},
        {field: "status", headerName: "Status", flex: 0.2},
        {
            field: "createdAt",
            headerName: "Created At Date",
            flex: 0.2,
        },
        {
            field: "updatedAt",
            headerName: "Updated At",
            flex: 0.2,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            renderCell: (params: GridCellParams) => {
                const onClick = () => {
                    setEditRow(params.row);
                    setUpdateOrAddModalOpen(true);
                };

                return <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    className="textPrimary"
                    onClick={onClick}
                    color="inherit"
                />;
            },
        },
    ];

    return (
        <Box m="20px">
            <Header
                title="Customers"
                subtitle="List of Custoemers"
            />
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar}/>
                </Snackbar>
            )}
            <Box display="flex" justifyContent="flex-start">
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setUpdateOrAddModalOpen(true)}
                    href={''}>
                    Add Customer
                </Button>
            </Box>
            <Dialog open={confirmDelete} onClose={handleConfirmClose}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>Are you sure you want to delete this item?</DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmClose}>No</Button>
                    <Button onClick={() => {handleDeleteSubmit(); handleConfirmClose()}} color="secondary">Yes</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deleteIsSuccess} onClose={() => {setDeleteIsSuccess(false); setSubmitted(false)}}>
                <DialogTitle>Success</DialogTitle>
                <DialogContent>The item has been successfully deleted.</DialogContent>
                <DialogActions>
                    <Button onClick={() => {setDeleteIsSuccess(false); setSubmitted(false)}}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={addIsSuccess} onClose={() => {setAddIsSuccess(false); setSubmitted(false)}}>
                <DialogTitle>Success</DialogTitle>
                <DialogContent>Your Customer has been added successfully.</DialogContent>
                <DialogContent>
                    <p id="modal-modal-description">ID: {addData ? addData.id : null}</p>
                    <p id="modal-modal-description">Created At: {addData ? addData.createdAt : null}</p>
                    <p id="modal-modal-description">Message: {addData?.message}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setAddIsSuccess(false);
                        setSubmitted(false)
                    }}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={updateIsSuccess} onClose={() => {setUpdateIsSuccess(false); setSubmitted(false)}}>
                <DialogTitle>Success</DialogTitle>
                <DialogContent>Your Customer has been updated successfully.</DialogContent>
                <DialogContent>
                    <p id="modal-modal-description">ID: {updateData ? updateData.id : null}</p>
                    <p id="modal-modal-description">Updated At: {updateData ? updateData.updatedAt : null}</p>
                    <p id="modal-modal-description">Message: {updateData?.message}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setUpdateIsSuccess(false);
                        setSubmitted(false)
                    }}>Close</Button>
                </DialogActions>
            </Dialog>
            <Modal
                open={updateOrAddModalOpen}
                onClose={() => {
                    setUpdateOrAddModalOpen(false)
                    setEditRow([])
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '40%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 800,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4
                }}>

                    <h2 id="modal-modal-title">{Object.keys(editRow).length > 0 ?  "Update Customer" : "Add new Customer" }</h2>
                    <Formik initialValues={initialValues}
                            onSubmit={(values, formikHelpers) => handleSubmit(values, formikHelpers)}
                            validationSchema={checkoutSchema}
                    >
                        {({values, errors, touched, handleChange, handleBlur}) => (
                            <Form>
                                <Grid container flex={'auto'} spacing={2}>
                                    <Grid style={{display: 'flex', flexDirection: 'column'}}>
                                        <Grid style={{display: 'flex', flexDirection: 'row'}}>
                                            <Grid item xs={3}>
                                                <Box
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    style={{}}
                                                >Company Name:</Box>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Company Name"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.companyName}
                                                    name="companyName"
                                                    error={!!touched.companyName && !!errors.companyName}
                                                    helperText={touched.companyName && errors.companyName}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid style={{display: 'flex', flexDirection: 'row'}}>
                                            <Grid item xs={3}>
                                                <Box
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    style={{}}
                                                >Customer Address:</Box>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Customer Address"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.address}
                                                    name="address"
                                                    error={!!touched.address && !!errors.address}
                                                    helperText={touched.address && errors.address}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid style={{display: 'flex', flexDirection: 'row'}}>
                                            <Grid item xs={3}>
                                                <Box
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    style={{}}
                                                >Postal Address:</Box>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Postal Address"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.postalAddress}
                                                    name="postalAddress"
                                                    error={!!touched.postalAddress && !!errors.postalAddress}
                                                    helperText={touched.postalAddress && errors.postalAddress}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid style={{display: 'flex', flexDirection: 'row'}}>
                                            <Grid item xs={3}>
                                                <Box
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    style={{}}
                                                >Vat No:</Box>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Vat No"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.vatNo}
                                                    name="vatNo"
                                                    error={!!touched.vatNo && !!errors.vatNo}
                                                    helperText={touched.vatNo && errors.vatNo}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid style={{display: 'flex', flexDirection: 'row'}}>
                                            <Grid item xs={3}>
                                                <Box
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    style={{}}
                                                >Status:</Box>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Status"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.status}
                                                    name="status"
                                                    error={!!touched.status && !!errors.status}
                                                    helperText={touched.status && errors.status}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Box display="flex" justifyContent="end" mt="20px">
                                    { Object.keys(editRow).length > 0 ? <Button color="secondary" variant="contained" onClick={() => {setConfirmDelete(true); setDeleteId(values.id ? values.id : 0)}} >
                                            Delete
                                        </Button> :
                                        null
                                    }
                                    <Button type="submit" color="secondary" variant="contained"  disabled={addLoading || updateLoading}>
                                        {addLoading || updateLoading ? <CircularProgress /> : Object.keys(editRow).length > 0 ?  "Update Customer" : "Add new Customer" }
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Modal>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colours.palette.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colours.palette.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colours.palette.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colours.palette.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colours.palette.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colours.palette.grey[100]} !important`,
                    },
                }}
            >
                {customers &&
                    <DataGrid
                        getRowId={getRowId}
                        rows={customers}
                        columns={columns}
                        editMode="row"
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        slots={{
                            toolbar: EditToolbar,
                        }}
                        slotProps={{
                            toolbar: {customers, setRowModesModel},
                        }}
                    />}
            </Box>
        </Box>
    );
};


export default Customers;