import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const borderColor = "#00519C";

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderColor: "#3778C2"
    },
    container: {
        flexDirection: "row",
        borderBottomColor: "#00519C",
        backgroundColor: "#fff",
        color: "#000000",
        borderBottomWidth: 1,
        alignItems: "center",
        height: 24,
        textAlign: "center",
        fontStyle: "bold",
        flexGrow: 1,
        fontSize: 12
    },
    sparesUsed: {
        width: "70%",
        borderRightColor: borderColor,
        borderRightWidth: 1,

    },
    jobDoneBy: {
        width: "30%",

    },
    approvedForeman: {
        width: "30%",
        textAlign: "left"
    },
    description70: {
        width: "70%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1
    },
    description30End: {
        width: "30%",
        textAlign: "left",
    },
    description30: {
        width: "30%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1
    },
    description100: {
        width: "100%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1
    },
    description100End: {
        width: "100%",
        textAlign: "left",
    }
});

const SparesUsed = ({ invoice }:any) => {
    return (

        <View style={styles.tableContainer}>
            {/* Invoice Table Header */}
            <View style={styles.container}>
                <Text style={styles.sparesUsed}>Spares Used</Text>
                <Text style={styles.jobDoneBy}>Job Done By</Text>
            </View>
            <View style={styles.container}>
                <Text style={styles.description70}> </Text>
                <Text style={styles.description30End}> </Text>
            </View>
            <View style={styles.container}>
                <Text style={styles.description70}> </Text>
                <Text style={styles.approvedForeman}>Approved Foreman</Text>
            </View>
            <View style={styles.container}>
                <Text style={styles.description100End}> </Text>
            </View>
            <View style={styles.container}>
                <Text style={styles.description100End}> </Text>
            </View>
        </View>
    );
}

export default SparesUsed;