import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {store} from "../store";
import {baseQueryWithReauth, productBaseQuery} from "./apiSlice";

export const customrsApi = createApi({
    reducerPath: 'customersApi',
    baseQuery: baseQueryWithReauth(productBaseQuery),

    tagTypes: ['customers'],
    endpoints: build => ({
        GetCustomers: build.query({
            providesTags: ['customers'],
            query:() => ({
                url: `/customers/`,
                method: 'GET',
            }),
            transformResponse: (response: any) =>  {
                return response.customers}
        }),
        GetCustomerByUserID: build.query({
            query:(customerId: string) => ({
                url: `/customers/${customerId}`,
                method: 'GET',
            }),
            transformResponse: (response: any) =>  {
                return response.customer}
        }),
        AddCustomer: build.mutation({
            query:(customer: any) => ({
                url: `/customers/`,
                method: 'POST',
                body: customer
            }),
            transformResponse: (response: any) =>  {
                response.customer.message = response.message || ""
                return response.customer},
            invalidatesTags: ['customers']
        }),
        UpdateCustomer: build.mutation({
            query:(customer: any) => ({
                url: `/customers/`,
                method: 'PUT',
                body: customer
            }),
            transformResponse: (response: any) =>  {
                response.customer.message = response.message || ""
                return response.customer},
            invalidatesTags: ['customers']
        }),
        DeleteCustomer: build.mutation({
            query:(id: number) => ({
                url: `/customers/${id}`,
                method: 'DELETE',
            }),
            transformResponse: (response: any) =>  {
                response.customer.message = response.message || ""
                return response.customer
            },
            invalidatesTags: ['customers']
        })
    })
})

export const  {
    useGetCustomersQuery,
    useGetCustomerByUserIDQuery,
    useAddCustomerMutation,
    useUpdateCustomerMutation,
    useDeleteCustomerMutation
} = customrsApi

